<template>
<span>
  <v-btn
       id="create-new-fiscal-year"
       class="mx-2" color="primary"
       @click="openDialogbox"
  >
      Create New Fiscal Year
  </v-btn>
  <v-dialog
    v-model="dialog"
    max-width="60vw"
   >
    <v-card class="CreateFiscalYear"
      @keydown.enter="save"
    >
    <v-card-title>
      <span class="headline">Create New Fiscal Year</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="2">
              <v-text-field class="createNew fiscalYear"
                id="odCreateNewFyText"
                v-model.number="currentYear"
                label="Fiscal Year"
                type="number"
                :rules="fiscalYearValidation"
                @keyup="onChange"
                @keydown="onChange"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field class="createNew type"
                id="odCreateNewTypeText"
                v-model="criteria.type"
                type="text"
                :rules="reqdSelectValidation"
                label="Type"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <ClarionDateControl class="createNew register"
                id="odCreateNewRegisterDate"
                v-model="registerDate"
                :isRequired="true"
                :isIsoDate="true"
                :label="`Register Date`"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <DepartmentSelection id="odCreateNewByDepartmentSelect"
                v-model="criteria.departments"
                :isLoadDeptExplicitly="false"
              />
            </v-col>
            <v-col cols='3'>
              <v-text-field class="createNew status"
                id="odCreateNewStatusText"
                v-model="criteria.status"
                type="text"
                :rules="reqdSelectValidation"
                label="Status"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="createNew close"
        id="odCreateNewCloseBtn"
        text color="blue darken-1"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn class="createNew save"
        id="odCreateNewSaveBtn"
        text color="blue darken-1"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</span>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ClarionDateControl from '../common/ClarionDateControl.vue';
import DepartmentSelection from '../common/DepartmentSelection.vue';
import { toShortDate, destructureDate, toIsoDate } from '../../util/shared/tmc-global';

import { clients } from '../../util/clients';

const { backendRest } = clients.direct;

export default {
  name: 'CreateFiscalYear',
  components: {
    ClarionDateControl,
    DepartmentSelection,
  },
  data: () => ({
    currentYear: destructureDate().year,
    registerDate: undefined,
    dialog: false,
    criteria: {
      departments: [],
      type: 'Beginning Balance',
      status: 'Outstanding',
    },
  }),
  props: {
    deptFiscalYear: {
      type: Number,
      default: () => destructureDate().year,
    },
  },
  created() {
    this.currentYear = destructureDate().year + 1;
    this.registerDate = toShortDate(`${destructureDate().year}-07-01`);
  },
  computed: {
    fiscalYearValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => !Number.isNaN(parseFloat(value)) || 'Numeric required',
        (value) => parseFloat(value) > 0 || 'Must be a positive year',
        (value) => parseFloat(value) < 3000 || 'Must be a reasonable year',
      ];
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    ...mapState({
      departments: (state) => state.OD.departments,
    }),
  },
  methods: {
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    ...mapActions('Bulk', [
      'upsertODTransactions',
    ]),
    ...mapActions('OD', [
      'loadFiscalYears',
      'loadDepartments',
    ]),
    openDialogbox() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    onChange() {
      const calculateYear = this.currentYear - 1;
      this.registerDate = toShortDate(`${calculateYear}-07-01`);
    },
    async callClearCache() {
      const group = ['ODFYDeptCache'];
      try {
        await backendRest.get(`cache-utility/clear-cache?group=${group}`);
      } catch (e) {
        console.error(e);
      }
    },
    async save() {
      if (window.confirm(`You are about to create beginning balance records for FY ${this.currentYear}, on current balances for the FY ${this.currentYear - 1}.`)) {
        const self = this;
        const selectedDept = [];
        const duplicateDept = [];
        const previousFiscalYear = this.currentYear - 1;
        const deptCriteria = {
          fiscalYear: previousFiscalYear,
          limit: 0,
        };
        const deptResponse = await this.loadDepartments(deptCriteria);
        for (let i = 0; i < this.criteria.departments.length; i += 1) {
          const snglDept = deptResponse.find(({ id }) => id === self.criteria.departments[i]);
          if (snglDept !== undefined) {
            selectedDept.push(snglDept);
          }
        }
        const response = await this.fetchOutStanding(this.currentYear);
        for (let j = 0; j < selectedDept.length; j += 1) {
          let oneDept = response.find(({ department }) => department === selectedDept[j].id);
          if (oneDept !== undefined) {
            oneDept = { currentAmount: selectedDept[j].deptBalance, ...oneDept };
            duplicateDept.push(oneDept);
          }
        }
        if (duplicateDept.length > 0) {
          for (let k = 0; k < duplicateDept.length; k += 1) {
            const rmv = selectedDept.findIndex((item) => item.id === duplicateDept[k].department);
            selectedDept.splice(rmv, 1);
          }
          alert(`Warning: beginning balance records already exist for FY ${this.currentYear}.`);
          const overWriteDept = duplicateDept.map((item) => ({
            type: item.type,
            registration: item.registration,
            fiscalYear: item.fiscalYear,
            deptNumber: item.departmentDetail.dept,
            amount: item.currentAmount,
          }));
          const overWriteUpsert = await this.upsertODTransactions(overWriteDept);
          const { ok } = overWriteUpsert.data.upsertODTransactions.result;
          if (!ok) {
            this.$store.dispatch('flashError', 'Some errored during overwriting Beginning Balance.');
          } else {
            this.$store.dispatch('flashSuccess', 'Overwrite Successful of Beginning Balance transactions');
          }
        }
        if (selectedDept.length > 0) {
          const upsertsData = selectedDept.map((item) => ({
            type: 'BEGINNINGBALANCE',
            registration: undefined,
            register: toIsoDate(this.registerDate),
            fiscalYear: this.currentYear,
            deptNumber: item.dept,
            status: 'OUTSTANDING',
            amount: item.deptBalance,
          }));
          const upsertResponse = await this.upsertODTransactions(upsertsData);
          const { ok } = upsertResponse.data.upsertODTransactions.result;
          if (!ok) {
            this.$store.dispatch('flashError', 'Some errored during creating Beginning Balance.');
          } else {
            this.$store.dispatch('flashSuccess', 'Successfully creating Beginning Balance transactions');
          }
          this.callClearCache();
        }
        this.loadFiscalYears();
        this.close();
      } else {
        this.close();
      }
    },
    async fetchOutStanding(fiscalYear) {
      const params = {
        status: 'OUTSTANDING',
        types: 'BEGINNINGBALANCE',
        fiscalYear,
        limit: 0,
      };
      return this.$store.dispatch('OD/searchTransactions', params);
    },
  },
};
</script>

<style>

</style>
